#cohubInvoicePaymentApp button {
  background: #e0e1e2;
  color: #5a5a5a;
  border: none;
  padding: 10px;
  border-radius: 4px; }

#cohubInvoicePaymentApp h1 {
  font-size: 1.2rem; }

#cohubInvoicePaymentApp .errors {
  background-color: #f75e5e;
  color: white;
  border-radius: 5px;
  padding: 10px; }

#cohubInvoicePaymentApp #cohubInvoicePaymentAppContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppContainer button {
    outline: 0; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppContainer .buttons #cohubInvoicePaymentAppContinueButton {
    color: white;
    background: #696fb8;
    float: right; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppContainer #cohubInvoicePaymentAppCloseButton {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    font-weight: 400;
    padding: 5px 10px 5px 10px; }
    #cohubInvoicePaymentApp #cohubInvoicePaymentAppContainer #cohubInvoicePaymentAppCloseButton:hover {
      border: 1px solid black; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppContainer > div {
    padding: 20px; }

#cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper {
  border-radius: 6px;
  width: 74%;
  position: fixed;
  top: 13%;
  left: 13%;
  background: white; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper > div {
    overflow: hidden;
    height: 100%; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper .content {
    overflow-y: auto;
    height: 90%; }
    #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper .content .item {
      padding: 10px;
      background: #F3F3F3;
      border-radius: 5px;
      margin-bottom: 10px; }
      #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper .content .item .item-label {
        font-weight: bolder; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper .modes {
    margin-bottom: 10px; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_InvoicePaymentAuthForm .header {
    display: none; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_InvoicePaymentDetailsForm .image-wrapper {
    text-align: center; }
    #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_InvoicePaymentDetailsForm .image-wrapper .product-image {
      text-align: center;
      max-width: 180px;
      height: auto; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_CardSelectForm form {
    width: 100%; }
    #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_CardSelectForm form .field {
      padding: 10px 0 10px 0; }
      #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_CardSelectForm form .field input {
        width: 5%;
        height: inherit; }
      #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_CardSelectForm form .field .brand {
        margin-left: 10px; }
      #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_CardSelectForm form .field .expires {
        float: right; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_EnterCardForm form .field input {
    vertical-align: middle; }
    #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_EnterCardForm form .field input.visa:before {
      background: #666666; }
    #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper #_cohub_EnterCardForm form .field input .visa:before {
      background: #666666; }

#cohubInvoicePaymentApp .header {
  border-bottom: 1px solid black;
  padding: 10px 0 10px 0;
  margin-bottom: 10px; }

#cohubInvoicePaymentApp .modes {
  text-align: center; }
  #cohubInvoicePaymentApp .modes .mode {
    background-color: white;
    color: black;
    font-size: 1.1rem;
    border-radius: 0; }
  #cohubInvoicePaymentApp .modes .mode.active {
    font-weight: 800;
    border-bottom: 1px solid black; }

#cohubInvoicePaymentApp form .field {
  color: #444;
  margin-bottom: 10px; }
  #cohubInvoicePaymentApp form .field label {
    color: #666;
    display: block; }
  #cohubInvoicePaymentApp form .field select {
    margin-left: 4px; }
  #cohubInvoicePaymentApp form .field input {
    border: 1px solid #999;
    border-radius: 4px;
    padding-left: 5px;
    width: 100%;
    height: 40px; }
    #cohubInvoicePaymentApp form .field input#quantity {
      max-width: 100px; }

#cohubInvoicePaymentApp form .fields {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column; }
  #cohubInvoicePaymentApp form .fields .field:first-child {
    margin-left: 0; }
  #cohubInvoicePaymentApp form .fields .field:last-child {
    margin-right: 0; }
  #cohubInvoicePaymentApp form .fields .field {
    width: 100%; }

#cohubInvoicePaymentApp .payment-options {
  margin-bottom: 5px; }
  #cohubInvoicePaymentApp .payment-options button.active {
    background: #999999;
    color: white; }
  #cohubInvoicePaymentApp .payment-options button:last-child {
    margin-left: 5px; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

#cohubInvoicePaymentApp .loader {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center;
  background: rgba(255, 255, 255, 0.2); }

#cohubInvoicePaymentApp .loading {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: .25rem solid #333333;
  border-top-color: white;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear; }

@media (min-width: 760px) {
  #cohubInvoicePaymentApp h1 {
    font-size: 2rem; }
  #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper {
    width: 50%;
    top: 25%;
    left: 25%; }
    #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper form .fields {
      -ms-flex-direction: row;
          flex-direction: row; }
      #cohubInvoicePaymentApp #cohubInvoicePaymentAppWrapper form .fields .field {
        margin-right: 5px; } }
